import * as React from "react"
import Layout from "../components/Layout"
import Wrapper from "../components/Wrapper"
import styled from "styled-components"
import ContactPrompt from "../components/ContactPrompt"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { graphql } from "gatsby"
import Card from "../components/Card"
import ImageWithText from "../components/ImageWithText"
import SectionWrapper from "../components/SectionWrapper"
import Background from "../components/Background"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const CaseHero = styled.div`
    background-color: ${props => props.theme.color.grey};
    @media (max-width: ${props => props.theme.size.medium}) {
        padding: 140px 0 0 0;
        .casehero-content {
            order: 1;
        }
    }
    h1 {
        line-height: 1.25em;
        margin-bottom: 15px;
        margin-top: 0;
        font-weight: 800;
        text-transform: uppercase;
    }
    & > div {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        & > div {
            width: 100%;
            &:first-child {
                position: relative;
                z-index: 1;
            }
        }
        .gatsby-image-wrapper {
            @media (min-width: ${props => props.theme.size.medium}) {
                overflow: visible !important;
            }
        }
        & > div:last-child img {
            width: 100%;
            transform: ${props =>
                props.enlargeImage !== false ? "scale(1)" : "scale(1)"};
        }
        @media (min-width: ${props => props.theme.size.medium}) {
            flex-direction: row;
            & > div:first-child {
                width: 40%;
            }
            & > div:last-child {
                width: 60%;
                img {
                    transform: ${props =>
                        props.enlargeImage !== false
                            ? "scale(1.5)"
                            : "scale(1)"};
                }
            }
        }
    }
`

const HeaderWrapper = styled(Wrapper)`
    @media (max-width: ${props => props.theme.size.medium}) {
        padding: 0;

        & div:first-child {
            box-sizing: border-box;
            padding: 0 15px;
        }
    }
`

const Testimonial = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 800px;
    .gatsby-image-wrapper img {
        object-fit: cover !important;
    }
    h3 {
        & + p {
            margin-top: 4px;
            font-size: 0.85em;
        }
        & + div {
            font-style: italic;
        }
    }
    & > div {
        width: 100%;
        @media (min-width: ${props => props.theme.size.medium}) {
            text-align: left;
            width: 75%;
            &:first-child {
                width: 25%;
                display: flex;
                align-items: center;
            }
            &:last-child {
                padding-left: 25px;
                box-sizing: border-box;
            }
        }
    }
    .gatsby-image-wrapper {
        margin: 0 auto;
        width: 100%;
        max-width: 175px;
        & > div:first-child {
            padding-bottom: 100% !important;
        }
    }
`

const Video = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    margin: 50px auto;
    & > div {
        margin-top: 50px;
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: ${props => (props.square ? "100%" : "56.25%")};
        @media (min-width: ${props => props.theme.size.medium}) {
            width: ${props => (props.square ? "75%" : "100%")};
            padding-bottom: ${props => (props.square ? "75%" : "56.25%")};
        }
        iframe {
            box-shadow: 10px 6px 59px 3px rgba(0, 0, 0, 0.11);
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }
`

/*=== CASE SECTIONS ===*/

const TestimonialSection = props => (
    <Background color={props.color}>
        <SectionWrapper>
            <Testimonial>
                <div className="rounded-full rounded-image inline-block">
                    <GatsbyImage
                        alt=""
                        className="aspect-square rounded-full"
                        image={props.image}
                    />
                </div>
                <div>
                    <h3 className="font-bold text-xl">{props.name}</h3>
                    <p>{props.title}</p>
                    <div
                        className="mt-2"
                        dangerouslySetInnerHTML={{ __html: props.text }}
                    />
                </div>
            </Testimonial>
        </SectionWrapper>
    </Background>
)

const VideoSection = props => (
    <Background
        gradient
        colorTop={props.colorTop}
        colorBottom={props.colorBottom}
    >
        <SectionWrapper>
            <Video square={props.square}>
                {props.title && <h2>{props.title}</h2>}
                <div>
                    <iframe
                        title={props.title}
                        src={"https://www.youtube.com/embed/" + props.youtubeID}
                        frameBorder="0"
                        allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                </div>
            </Video>
        </SectionWrapper>
    </Background>
)

const CaseLinks = ({ data }) => {
    const caseIdx = data.datoCmsWork.position - 1
    const prevIdx =
        (caseIdx - 1) % data.cases.edges.length < 0
            ? data.cases.edges.length - 1
            : caseIdx - 1
    const nextIdx = (caseIdx + 1) % data.cases.edges.length
    const prevCase = data.cases.edges[prevIdx]
    const nextCase = data.cases.edges[nextIdx]
    return (
        <>
            {(prevCase || nextCase) && (
                <Wrapper>
                    <div className="my-10 flex gap-10 flex-wrap md:flex-nowrap">
                        {prevCase && (
                            <Card
                                className="md:basis-1/2"
                                title={prevCase.node.title}
                                subtitle="Forrige case"
                                link={`/cases/${prevCase.node.slug}`}
                                image={getImage(prevCase.node.featuredImage[0])}
                                imageClasses="brightness-90 aspect-video"
                            />
                        )}
                        {nextCase && (
                            <Card
                                className="md:basis-1/2"
                                title={nextCase.node.title}
                                subtitle="Næste case"
                                link={`/cases/${nextCase.node.slug}`}
                                image={getImage(nextCase.node.featuredImage[0])}
                                imageClasses="brightness-90 aspect-video"
                            />
                        )}
                    </div>
                </Wrapper>
            )}
        </>
    )
}

const WorkPage = ({ data }) => {
    return (
        <Layout>
            <HelmetDatoCms>
                <body className="text-gray-800" />
                <title>
                    {data.datoCmsWork.siteseo && data.datoCmsWork.siteseo.title}
                </title>
                <meta name="theme-color" content="#F2F2F2" />
                <meta
                    name="description"
                    content={
                        data.datoCmsWork.siteseo &&
                        data.datoCmsWork.siteseo.description
                    }
                />
            </HelmetDatoCms>
            <CaseHero enlargeImage={data.datoCmsWork.enlargeImage}>
                <HeaderWrapper>
                    <div className="casehero-content">
                        <h1 className="text-4xl md:text-6xl break-words md:break-normal">
                            {data.datoCmsWork.title}
                        </h1>
                        <a
                            className="text-hybl font-bold hover:text-hybl-300"
                            href={data.datoCmsWork.liveLink}
                            target="__blank"
                        >
                            {data.datoCmsWork.liveLink.replace(
                                /.*:\/\/|\/$/g,
                                ""
                            )}
                        </a>
                        <div
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                                __html: data.datoCmsWork.excerpt,
                            }}
                        />
                    </div>
                    <GatsbyImage
                        image={getImage(data.datoCmsWork.featuredImage[1])}
                    />
                </HeaderWrapper>
            </CaseHero>
            {data.datoCmsWork.caseSections.map((section, index) => (
                <section key={section.id + "" + index}>
                    {section.model.apiKey === "testimonial" &&
                        section.author && (
                            <TestimonialSection
                                image={getImage(section.author)}
                                name={section.authorName}
                                title={section.authorTitle}
                                text={section.testimonial}
                                color={
                                    section.color
                                        ? section.color.rgb
                                        : "rgb(255, 255, 255)"
                                }
                            />
                        )}
                    {section.model.apiKey === "image" && section.image && (
                        <ImageWithText
                            title={section.title}
                            image={getImage(section.image)}
                            text={section.description}
                            flip={section.flip}
                            color={
                                section.color
                                    ? section.color.rgb
                                    : "rgb(255, 255, 255)"
                            }
                        />
                    )}
                    {section.model.apiKey === "case_pagination" && (
                        <CaseLinks data={data} />
                    )}
                    {section.model.apiKey === "video" && section.youtubeId && (
                        <VideoSection
                            title={section.title}
                            youtubeID={section.youtubeId}
                            square={section.squareVideo}
                            colorTop={section.colorTop.rgb}
                            colorBottom={section.colorBottom.rgb}
                        />
                    )}
                    {section.model.apiKey === "contact" && (
                        <ContactPrompt
                            title={section.title}
                            description={section.description}
                            number={section.phoneNumber}
                            mail={section.eMail}
                        />
                    )}
                </section>
            ))}
        </Layout>
    )
}

export default WorkPage

export const query = graphql`
    query WorkQuery($slug: String!) {
        cases: allDatoCmsWork {
            edges {
                node {
                    ...caseFields
                }
            }
        }
        datoCmsWork(slug: { eq: $slug }) {
            siteseo {
                title
                description
            }
            enlargeImage
            ...caseFields
            caseSections {
                ... on DatoCmsCasePagination {
                    ...paginationFields
                }
                ... on DatoCmsImage {
                    ...imageFields
                }
                ... on DatoCmsTestimonial {
                    ...testimonialFields
                }
                ... on DatoCmsVideo {
                    ...videoFields
                }
                ... on DatoCmsContact {
                    ...contactFields
                }
            }
        }
    }
`
