import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Card = props => {
    const { title, subtitle, image, link, className, imageClasses } = props
    return (
        <Link to={link} className={`group ${className}`}>
            <div
                className={`relative bg-grey group-hover:scale-95 duration-300 transition-transform`}
            >
                <div
                    className={`p-4 z-10 ${image ? "absolute text-white" : ""}`}
                >
                    {subtitle && (
                        <p
                            className={`uppercase font-bold text-xs ${
                                image ? " text-white" : ""
                            }`}
                        >
                            {subtitle}
                        </p>
                    )}
                    {title && (
                        <p
                            className={`uppercase font-bold text-xl ${
                                image ? " text-white" : ""
                            }`}
                        >
                            {title}
                        </p>
                    )}
                </div>
                {image && (
                    <div className={`overflow-hidden flex items-center`}>
                        <GatsbyImage
                            alt=""
                            image={image}
                            className={`w-full group-hover:scale-110 transition-transform duration-300 ${imageClasses}`}
                        />
                    </div>
                )}
            </div>
        </Link>
    )
}

export default Card
